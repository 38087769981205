.TableFooter-module_table-footer_1GIJi__Stat {
  background-color: var(--bg-block);
}

.TableFooter-module_table-footer__labels_HaroH__Stat {
  border-top: 1px solid var(--line-line);
  border-bottom: 1px solid var(--line-line);
  flex-wrap: wrap;
  gap: 20px 40px;
  padding: 20px 10px;
  display: flex;
}

.TableFooter-module_table-footer__labels-pair_UVWy5__Stat {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.TableFooter-module_table-footer__label_LvSmT__Stat {
  gap: 5px;
  display: flex;
}

.TableFooter-module_table-footer__label--rank-up_sc1N3__Stat svg {
  fill: var(--icons-green);
}

.TableFooter-module_table-footer__label--rank-down_vWRzB__Stat svg {
  fill: var(--icons-red);
  transform: rotate(180deg);
}

.TableFooter-module_table-footer__label--rank-same_HJwa4__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  content: "";
  background-color: var(--icons-gray);
  border-radius: 1px;
  width: 8px;
  height: 2px;
  display: block;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 4px);
}

.TableFooter-module_table-footer__label--outcome_J9-gp__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  content: "";
  border-radius: 4px;
  width: 8px;
  height: 4px;
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: calc(50% - 4px);
}

.TableFooter-module_table-footer__label--secondary-main_V2MC5__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-champions-league);
}

.TableFooter-module_table-footer__label--secondary-dark_ipaG2__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-champions-league-playoff);
}

.TableFooter-module_table-footer__label--yellow-light_b0pLU__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-european-cup-playoff);
}

.TableFooter-module_table-footer__label--yellow-main_UoQpG__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-european-cup);
}

.TableFooter-module_table-footer__label--green-main_zkqcO__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-conference-league);
}

.TableFooter-module_table-footer__label--green-light_SCd1r__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-conference-league-playoff);
}

.TableFooter-module_table-footer__label--gray-light_hvZu-__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-relegation);
}

.TableFooter-module_table-footer__label--red-main_gEvlr__Stat .TableFooter-module_table-footer__label-icon_9MFmC__Stat:before {
  background-color: var(--icons-leagues-left);
}

.TableFooter-module_table-footer__label-icon_9MFmC__Stat {
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  display: flex;
  position: relative;
}

.TableFooter-module_table-footer__label-caption_8KbDA__Stat {
  color: var(--text-secondary);
}

.TableFooter-module_table-footer__legends_fcNPV__Stat {
  flex-flow: wrap;
  gap: 10px;
  width: 100%;
  padding: 10px;
  display: flex;
}

.TableFooter-module_table-footer__legend-shorted_w4jMm__Stat {
  color: var(--text-primary);
}

.TableFooter-module_table-footer__legend-desc_itJ1y__Stat {
  color: var(--text-secondary);
}

@media (max-width: 660px) {
  .TableFooter-module_table-footer__labels_HaroH__Stat {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}

.AllGroups-module_all-groups_yZ6MW__Stat {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.AllGroups-module_all-groups__table_bXv7T__Stat {
  border: 1px solid var(--line-line);
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
}

.AllGroups-module_all-groups__group_VKnC4__Stat {
  text-transform: uppercase;
  color: var(--text-primary);
  padding: 0 10px 10px;
}

@media (max-width: 660px) {
  .AllGroups-module_all-groups__table_bXv7T__Stat {
    border-bottom: 0;
    border-left: none;
    border-right: none;
  }
}

